// @import url('https://fonts.googleapis.com/css2?family=Cormorant+Unicase:wght@600&family=Noto+Sans+TC&family=Noto+Serif+TC:wght@600&display=swap')
@import url('//fonts.googleapis.com/earlyaccess/notosanstc.css')
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@400;600&display=swap')
@import "reset"
@import "helpers/variables"
@import "helpers/extend"
@import "helpers/mixin"
@import "bootstrap"

:root
  --bs-border-color: #727272

[v-cloak]
  display: none

.fix-transform-bug
  -webkit-transform-style: preserve-3d

body
  position: relative
  background-color: white
  color: $font
  font-size: 14px
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: 'Noto Sans TC', 'Helvetica Neue', 'Helvetica', Roboto, 'Microsoft JhengHei', "Microsoft YaHei", "Apple Color Emoji", Arial, sans-serif
  &.modal-open
    padding-right: 0px !important
    .mm-slideout
      z-index: inherit !important

.container
  +xl-up
    padding-left: 75px
    padding-right: 75px

.serif
  font-family: 'Noto Serif TC', serif
.fw-600
  font-weight: 600
.fz-15
  +font-size(15px)
.fz-16
  +font-size(16px)
.fz-17
  +font-size(17px)
.fz-18
  +font-size(18px)
.fz-19
  +font-size(19px)
.fz-20
  +font-size(20px)
.fz-team
  +font-size(20px)
  color: $font-dark
  letter-spacing: 2.5px
  small
    +font-size(14px)
    color: $font-light
  &.border-bottom
    padding-bottom: 12px
.fz-title
  +font-size(19px)
  &.border-bottom
    padding-bottom: 12px
    // border-color: $gray-500 !important
a
  color: $font
  text-decoration: none
  &:hover,&:focus,&:active,&.active
    color: $primary
    text-decoration: none
    outline: 0
  &.primary-link
    color: $primary
    text-decoration: underline
    &:hover,&:focus,&:active,&.active
      color: $primary
  &.dagner-link
    color: $danger
    text-decoration: underline
    &:hover,&:focus,&:active,&.active
      color: $danger

.form-control
  border: 0
  background: $gray-100
  &:focus
    box-shadow: none
    background: $gray-100
  &::placeholder
    color: $gray-200
    opacity: 1
  &:-ms-input-placeholder
    color: $gray-200
  &::-ms-input-placeholder
    color: $gray-200
.form-text
  color: $font-light

.opacity-50
  opacity: 0.5
.opacity-80
  opacity: 0.8

.btn
  display: inline-flex
  align-items: center
  justify-content: center
  transition: .2s all ease-out
  border-radius: 0
  &:focus
    outline: 0
    box-shadow: none
  &.more-wider
    padding-left: 1.5rem
    padding-right: 1.5rem
    min-width: 160px
  &.btn-outline-white
    border-color: #FFFFFF
    color: $primary
  .arr-right
    display: inline-block
    width: 8px
    margin-left: .5rem
    svg
      width: 100%
      float: left
  &.max-width-210
    max-width: 210px
    width: 100%
    &:hover
      letter-spacing: 2px
  &.w-100
    &:hover
      letter-spacing: 2px
  &.btn-primary
    color: #fff
  &.btn-danger
    color: #fff
    &:hover,&:focus
      color: #fff
  &.btn-line
    color: #fff
    background-color: #01C101
    &:hover,&:focus
      color: #fff
      background-color: darken(#01C101, 10%)
  &.btn-secondary
    color: #FFF

@keyframes animatedBackground
  0%
    background-position: 0 0
  100%
    background-position: 300% 0

.page-header
  background: #DBD6D3
  box-shadow: 0 0 15px rgba(0,0,0,.2)
  &.isHide
    transform: translateY(-100%)
  &.isSticky
    z-index: 1203 !important
    backdrop-filter: blur(5px)
    background: rgba(255,255,255,0.5)
    @extend %box-shadow
    .navbar-nav
      .nav-link
        color: #000
        &:hover, &:focus, &:active, &.active
          color: #000
          font-weight: blod
  .dropdown
    .dropdown-menu
      border: 0
      box-shadow: 0 0 15px rgba(0,0,0,.3)
      padding-top: 20px
      padding-bottom: 20px
      margin-top: -1px
      &.show
        top: 100%
      .dropdown-item
        position: relative
        padding: 0.4rem 2rem 0.4rem 3rem
        +font-size(15px)
        &:after
          content: ''
          display: block
          width: 2.5rem
          height: 2px
          background: $primary
          position: absolute
          top: 50%
          left: 0
          display: none
        &:hover,&:focus
          background: transparent
          color: $primary
          &:after
            display: block
        &.active
          background: transparent
          color: $primary
          font-weight: 600
          &:hover
            background: transparent
            color: $primary
            &:after
              display: block
          &:after
            display: block


  .header-above
    color: #FFF
    ul
      li
        &:not(:last-child)
          &:after
            content: '/'
            display: inline-block
    a
      color: #FFF
      display: inline-block
      padding: 4px 12px
      font-size: 0.8rem
  .navbar
    justify-content: initial
    +sm-down
      justify-content: center
    .isSticky &
      padding: .25rem 0
    .navbar-collapse
      justify-content: flex-end
    .menutoggler
      border: 0
      width: 50px
      height: 50px
      display: flex
      align-items: center
      justify-content: center
      position: absolute
      top: 50%
      left: 0
      transform: translateY(-50%)
      &:hover, &:focus
        outline: 0
        .menu-icon
          &_bar
            background-color: $primary
    .navbar-brand
      font-size: 2.2rem
      +lg-down
        margin: .25rem auto
        font-size: 1.62rem
  .navbar-nav
    font-weight: 200
    font-size: 0.9rem
    flex-direction: row
    .nav-item
      position: relative
      font-weight: 400
      padding: 0
      display: flex
      align-items: center
      .btn
        padding: .5rem 2rem
        font-size: 0.9rem
        +sm-down
          padding: .5rem 1.5rem
          font-size: 0.875rem
    .nav-link
      color: $font
      position: relative
      z-index: 10
      padding: .8rem 1.2rem
      display: flex
      align-items: center
      font-size: 0.9rem
      +md-down
        padding: .75rem 1rem
      &:hover, &:focus, &:active, &.active
        color: $font-dark
        font-weight: 500
        &::before
          width: calc(100% - 2rem)
      .isSticky &
        font-size: 1rem
      .text
        z-index: 30
        position: relative
      .icon
        width: 17px
        display: inline-block
        margin-right: .3rem
        path
          fill: #ffffff
      &::before
        @extend %menu-animation
        animation-delay: .2s
        content: ''
        display: block
        position: absolute
        height: 2px
        width: 0%
        left: 50%
        bottom: 12px
        transform: translateX(-50%)
        max-width: auto
        background-color: $secondary
        z-index: 0
  .navbar-brand
    padding: 0
    color: #FFF
    .header-logo
      width: 180px
      transition: .3s width ease-out
      line-height: 0
      +xl-down
        width: 120px
      +md-down
        width: 136px
  .nav-secrch
    .search-group
      position: relative
      min-width: 200px
      +md-down
      +xl-down
        min-width: 100px
      .btn
        position: absolute
        top: 0
        right: 0
        width: 40px
        height: 100%
        img
          width: 16px
      input
        background: transparent
        padding-left: 0
        padding-right: 40px
        border: 0
        border-bottom: 1px solid $dark
        &:focus
          box-shadow: none

.row
  &.gutters-xl
    margin-left: -55px
    margin-right: -55px
    +md-down
      margin-left: -15px
      margin-right: -15px
    +sm-down
      margin-left: -10px
      margin-right: -10px
    *[class^='col']
      padding-left: 55px
      padding-right: 55px
      +md-down
        padding-left: 15px
        padding-right: 15px
      +sm-down
        padding-left: 10px
        padding-right: 10px
  &.gutters-lg
    margin-left: -35px
    margin-right: -35px
    +md-down
      margin-left: -15px
      margin-right: -15px
    +sm-down
      margin-left: -10px
      margin-right: -10px
    *[class^='col']
      padding-left: 35px
      padding-right: 35px
      +md-down
        padding-left: 15px
        padding-right: 15px
      +sm-down
        padding-left: 10px
        padding-right: 10px
.mm-menu
  // background: $gray-700
  &.mm-theme-dark
    background: $gray-700
    .mm-listview
      .nav-item
        &.active,&:hover,&:focus
          color: $primary
          background: darken($gray-700, 5%)
          .nav-link, .mm-next
            color: $primary
        .nav-link, .mm-next
          color: $white
      a
        color: $white
        &.active,&:hover,&:focus
          color: $primary
.slick-slider
  .slick-track
    transform: none
  .slick-list
    transform: none

.slick-slide
  img
    width: 100%
.slick-arrow
  position: absolute
  top: calc(50% - 23px)
  text-indent: 999px
  overflow: hidden
  background: transparent
  width: 46px
  height: 46px
  margin: 0
  border: 0
  z-index: 561
  border-radius: 50%
  &::after
    content: ''
    display: block
    border-left: 2px solid $font-gray
    border-bottom: 2px solid $font-gray
    transform: rotate(45deg)
    width: 20px
    height: 20px
    position: absolute
    top: 14px
    left: 18px
  &:hover
    outline: 0
    &::after
      border-color: $gray-900
  &:focus
    outline: 0
  &.slick-disabled
    opacity: .3
    cursor: not-allowed
    &:after
      border-color: $gray-900
  &.slick-prev
    left: -50px
  &.slick-next
    right: -50px
    &::after
      right: 18px
      left: auto
      transform: rotate(225deg)
.slick-dots
  margin: 0
  padding: 0
  text-align: center
  position: absolute
  bottom: 0px
  left: 0
  width: 100%
  > li
    display: inline-block
    line-height: 0
    &.slick-active
      > button
        background: $gray-900
        border-color: $gray-900
    > button
      width: 10px
      height: 10px
      background: transparent
      border: 1px solid $gray-500
      border-radius: 50%
      padding: 0
      &:hover, &:focus
        margin: 0 3px
      margin: 0 3px
      text-indent: -9999px
      overflow: hidden
      img
        transition: width .2s cubic-bezier(0, 0, 0.44, 1.18)
      &:hover,&:focus,&:active,&.active
        outline: 0 !important

//default state
$mu-icon-size:  24px
.menu-icon
  // height: $mu-icon-size
  width: 24px
  // position: absolute
  z-index: 2
  cursor: pointer
  &_bar
    height: 2px
    width: 24px
    display: block
    background-color: $font-dark
    margin-bottom: 6px
    transition: transform .2s ease, background-color .5s ease
    .isSticky &
      background-color: $font-dark
  &_bar-first
    // width: $mu-icon-size / 2
  &_bar-last
    margin-bottom: 0
    // width: $mu-icon-size / 2
    float: right

.mm-opened
  .mm-page
    overflow: hidden
  // #LaunchPage
  //   position: relative
  .menu-icon
    &_bar
      transform: translateX(0px) rotate(-45deg)
    &_bar-first
      transform: translate(1px, 3px) rotate(45deg)
      width: calc($mu-icon-size / 2)
    &_bar-last
      transform: translate(-1px, -3px) rotate(45deg)
      width: calc($mu-icon-size / 2)
      float: right
.section-hr
  height: 6px
  display: flex
  align-items: center
  background: $dark
  span
    display: block
    height: 100%
    &.bar1
      flex: 0 0 10%
      width: 10%
    &.bar2
      flex: 0 0 40%
      width: 40%
    &.bg-primary-dark
      background: $secondary
.section-hgroup
  .nav-tab
    .tabs
      margin: 0
      padding: 0
      display: flex
      grid-gap: 20px
      .tab-item
        &.active
          a
            color: $secondary
        a
          &:hover, &:focus
            color: $secondary
.section-hgroup
  .article-heading
    +font-size(18px)
    color: $font-dark
    flex-grow: 1
    line-height: 1.6
    +md-down
      line-height: 1.37
  .date
    flex: 0 0 120px
    width: 120px
    text-align: right
    color: $font-dark
    +font-size(16px)
    line-height: 1.6
    +md-down
      width: 100%
      margin-top: 1rem
section
  &.section-panel
    color: $font-gray
    .panel-body
      padding-top: 30px
      padding-bottom: 30px
    .panel-block
      +font-size(14px)
      .block-title
        +font-size(18px)
        font-weight: 500
    .offset-container-pl
      margin-left: -75px
      padding-left: 75px
      padding-top: 6px
      padding-bottom: 6px
      padding-right: 15px
      &.bg-brown
        background: #604C3F
  ul, ol
    list-style-position: outside
  ol
    list-style: decimal
    padding-left: 20px
    &.lower-latin
      list-style: lower-latin
    &.upper-latin
      list-style: upper-latin
  hgroup
    margin-bottom: 1rem
    .section-heading
      font-weight: 500
      color: $dark
      +font-size(19px)
      margin-bottom: 1.5rem
      +md-up
        +font-size(22px)
    .more
      +font-size(16px)
      font-weight: 500
      color: $font-light
      +md-up
        +font-size(18px)

  .section-container
    padding-left: 20px
    padding-right: 20px
    +md-up
      padding-left: 80px
      padding-right: 80px
    +lg-up
      padding-left: 100px
      padding-right: 100px

.hero-banner
  position: relative
  .slide-item
    position: relative
    .more
      position: absolute
      bottom: 0
      right: 73.45%
      +sm-down
        display: none
      .btn
        padding: 1.5rem 2.8rem
        +lg-down
          padding: 1.2rem 2rem
    .item-img
      +md-down
        position: relative
        padding-bottom: 110%
      img
        +md-down
          position: absolute
          width: 100%
          height: 100%
          top: 0
          left: 0
          object-fit: cover


.page-footer
  .footer-below
    background-color: $gray-600
    a
      color: $font-light
      &:hover,&:focus,&.active,&:active
        color: $white
  .below-container
    display: flex
    align-items: center
    justify-content: space-between
    +font-size(14px)
    +sm-down
      +font-size(13px)
      flex-direction: column
      padding-bottom: 15px
  .copyright
    padding: 1rem 0
    color: rgba(255,255,255,.5)
    text-align: center
    a
      display: inline-block
      padding: 2px 10px
  .social-icon
    display: flex
    align-items: center
    grid-gap: 15px
    .icon
      &.wechat
        svg
          width: 26px
          height: 26px
      &.youtube
        svg
          width: 22px
          height: 22px
      svg
        width: 20px
        height: 20px
        path
          fill: #FFF
  .footer-above
    padding: 2rem 0
    background: $gray-200
    +md-down
      padding: 1rem 0
    .above-content
      display: flex
      justify-content: center
      .logo
        width: 96px
        +md-down
          width: 66px

.pagination
  display: flex
  align-items: center
  justify-content: center
  margin-top: 2rem
  margin-bottom: 2rem
  .page-item
    &:hover, &:focus, &:active, &.active
      .page-link
        background-color: $primary
        color: $white
        border-color: $primary
    &.disabled
      .page-link
        background-color: rgba($gray-200,0.2)
        color: $gray-500
        border-color: $gray-200
        opacity: .5
    .page-link
      border-radius: 50%
      width: 36px
      height: 36px
      margin: 0 5px
      display: flex
      align-items: center
      justify-content: center
      padding: 0
      text-align: center
      background-color: transparent
      color: $font
      +font-size(16px)
      background: #000
      border: 1px solid $gray-500
      +sm-down
        +font-size(14px)
      &.previous, &.next
        +md-up
          width: auto
          padding: 0 12px
          border-radius: 50px
      &.previous
        +md-up
          padding-right: 20px
      &.next
        +md-up
          padding-left: 20px
      svg
        width: 20px

.accordion
  --bs-accordion-border-color: #727272
  --bs-accordion-btn-focus-box-shadow: none
  --bs-accordion-color: #727272
  --bs-accordion-active-bg: transparent
  color: $font-gray
.accordion-item
  border-bottom: $font-gray 1px solid !important
  +lg-up
    padding-right: 35%
  &:first-of-type
    border-top: $font-gray 1px solid !important
  .accordion-button
    color: $font-light
    +sm-down
      padding-left: 0
      padding-right: 0
    &:not(.collapsed)
      color: $font-light
      box-shadow: none
  .accordion-body
    padding-top: 0
    padding-left: 50px
    padding-right: 80px
    +sm-down
      padding-left: 30px
      padding-right: 40px


.static-content
  color: $font-light
  +font-size(15px)
  line-height: 1.85
  p, li
    margin-bottom: 0.5rem
  ul
    padding-left: 0
    &.first
      > li
        margin-bottom: 25px
    &.style-disc
      > li
        list-style: disc
  li
    list-style-position: inside
    > ul, ol
      padding-left: 20px
    > ol
      list-style: cjk-ideographic
  img
    max-width: 100%
  h1,h2,h3,h4,h5,h6
    font-weight: 600
    color: $font
    margin-bottom: 15px
  h1
    +font-size(32px)
  h2
    +font-size(24px)
  h3
    +font-size(20px)
  h4
    +font-size(18px)
  h5
    +font-size(16px)
  h6
    +font-size(14px)

.galleryModal
  .gallery-warpper
    figure
      figcaption
        font-size: 1.25rem
        color: $font-dark
        margin-bottom: .85rem

.card
  border: 0
  border-radius: 0
  &.card-news
    margin-bottom: 30px
    .card-title
      color: $font-light
      +font-size(14px)
      margin-bottom: 6px
  &.card-team
    color: $font-light
    .card-title
      +font-size(16px)
      font-weight: 600
    .card-text
      +font-size(14px)

  &.card-auction
    margin-bottom: 30px
    .card-title
      color: $font-light
      +font-size(16px)
      margin-bottom: 6px
    .card-text
      color: $font-light
    .date
      color: $font-light
      +font-size(14px)
  &.card-slider
    margin-bottom: 30px
    .card-title
      color: $font-light
      +font-size(16px)
      margin-bottom: 6px
    .card-tetx
      color: $font-light
  &.card-history
    color: $font-light
    margin-bottom: 30px
    .card-body
      border-right: 4px solid $primary
    .card-title
      +font-size(16px)
    .card-text
      +font-size(15px)
    p
      margin-bottom: .25rem
  .card-title
    line-height: 1.48
// modal Search
.modal-content
  .hash-group
    padding-top: 1rem
    margin-left: -.5rem
    margin-right: -.5rem
    a
      &.text-link
        font-size: .9rem
        color: #558fb9
        display: inline-block
        padding: .25rem .5rem
  .form-search-group
    .form-select
      border-width: 0 0 1px 0
      border-radius: 0
      width: 100%
      height: 60px
      font-weight: 300
      font-size: 1.25rem
      border-color: $gray-600
      +sm-down
        font-size: 1.1rem
      &:focus
        outline: 0
        box-shadow: 0 0 0 rgba(0,0,0,0)
    .form-search-input
      position: relative
      .customize-input
        cursor: text
        border: none
        color: #e4e4e4
        font-weight: 300
        font-size: 1.25rem
        width: 100%
        height: 60px
        padding: 0 0 0 .8rem
        border-bottom: 1px solid $gray-600
        color: $gray-900
        border-radius: 0
        +sm-down
          font-size: 1.1rem
        &:focus
          outline: 0
      .btn
        position: absolute
        right: 0
        top: 8px
        width: 50px
        height: 50px
        border: none
        padding: 0
        margin: 0
        .icn
          display: inline-block
          width: 20px
          svg
            width: 100%
            g
              stroke: $gray-800
      ::placeholder
        color: $gray-900
      ::-webkit-input-placeholder
        color: $gray-900
      :-ms-input-placeholder
        color: $gray-900
      ::-moz-placeholder
        color: $gray-900

#goTop
  width: 42px
  height: 42px
  border-radius: 50%
  background: $secondary
  position: fixed
  bottom: 30px
  right: 30px
  z-index: 982
  display: none
  align-items: center
  justify-content: center
  cursor: pointer
  &.show
    display: flex
  svg
    width: 18px
    height: 18px
    stroke: #fff

.topbanner
  position: relative
  background: #000
  .text-group
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    z-index: 8
    color: #fff
    display: flex
    align-items: center
    > .container
      position: relative
      z-index: 12
    .breadcrumb
      color: #FFF
    .breadcrumb-item
      color: #FFF
      +font-size(28px)
      font-weight: 300
      +md-down
        +font-size(20px)
      +sm-down
        +font-size(16px)
      &::before
        color: #FFF
        font-weight: 300
      &.active
        a
          font-weight: 300
      a
        color: #FFF
    &:after
      content: ''
      display: block
      width: 100%
      height: 100%
      position: absolute
      top: 0
      left: 0
      z-index: 0
      background: linear-gradient(0deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 100%)
.ratio
  &.ratio-banner
    opacity: 0.5
    &::before
      --bs-aspect-ratio: 23.6%
      +md-down
        --bs-aspect-ratio: 42.6%
      +lg-down
        --bs-aspect-ratio: 52.6%
    &.contact
      &::before
        --bs-aspect-ratio: 36%
        +md-down
          --bs-aspect-ratio: 40%
        +lg-down
          --bs-aspect-ratio: 76%
  img
    object-fit: contain
    background: $gray-200

.valuation-icons-group
  margin: 0 -25px
  padding: 0
  +md-down
    margin: 0 -15px
  +sm-down
    margin: 0 -5px
  .icon-item
    // max-width: 240px
    padding: 0 25px
    text-align: center
    +font-size(16px)
    +md-down
      +font-size(14px)
      padding: 0 15px
    +sm-down
      padding: 0 5px
    .icon
      width: 60px
      margin-bottom: 15px
      +md-down
        width: 50px
.slider-case
  .slick-slide
    margin: 0 30px
    +xl-down
      margin: 0 15px
  .slick-list
    margin: 0 -30px
    +xl-down
      margin: 0 -15px
.artist-search-group
  .btn-search
    background: $gray-100
    img
      width: 16px
.artist-index
  .index-title
    +font-size(20px)
    font-weight: 500
    color: $font
  .index-link
    display: inline-block
    color: $font-light
    padding: 6px 0
    &:hover, &:focus
      color: $font-dark
      text-decoration: underline

.artwork-container
  +font-size(14px)
  .artwork-information
    padding-right: 50px
    border-right: 5px solid $primary
  .price-group
    p
      margin-bottom: 2px
  .number
    +font-size(20px)
  .auction-venue
    +font-size(20px)
  .auction-title
    +font-size(20px)
  .artist
    +font-size(16px)
  p
    margin-bottom: 4px
.loading-more-button
  display: flex
  justify-content: center
  .btn-more
    color: $font-light
    display: flex
    flex-direction: column
    align-items: center
    +font-size(14px)
    line-height: 1.2

.auction-list-item
  .auction-info
    color: $font-light
    .auction-title, .date
      +font-size(18px)
      text-align: center
  .btn-brush
    width: 200px
    padding: 0
    position: relative
    span
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      display: flex
      align-items: center
      justify-content: center
      color: #FFF
      z-index: 12

.auction-form-container
  overflow-x: hidden
  .field-item
    display: flex
    flex-wrap: wrap
    .col-form-label
      flex: 0 0 160px
      width: 160px
      +sm-down
        flex: 0 0 100%
        width: 100%
    .col-input
      flex-grow: 1
  .btn-wrapper
    display: flex
    justify-content: center
    grid-gap: 15px
    +sm-down
      flex-direction: column
    .btn
      min-width: 180px
      +sm-down
        width: 100%
.rwd-wrapper
  +md-down
    overflow-x: scroll
.auction-upload-item
  +md-down
    min-width: 680px
  .item-group
    display: grid
    grid-gap: 20px
    grid-template-columns: repeat(3, 1fr) 80px
    &.header
      > div
        display: flex
        align-items: flex-end
      .control
        justify-content: center
    .icon
      width: 20px
.auction-upload-footer
  display: flex
  justify-content: flex-end
  .control
    width: 80px
  .icon
    width: 20px

.table
  &.table-light
    tr
      td, th
        border: 1px solid #FFF
        vertical-align: middle
        color: $font-light

.team-container
  margin: 0 160px
  +xl-down
    margin: 0px 80px
  +md-down
    margin: 0px 60px
  +sm-down
    margin: 0px 60px
  .card-team-grid-wrapper
    display: grid
    grid-template-columns: repeat(3, 1fr)
    // justify-content: space-between
    grid-gap: 20px 40px
    +xl-down
      grid-gap: 20px 30px
    +md-down
      grid-gap: 20px
    +sm-down
      display: block
  .card-team-single-wrapper
    margin: 0 160px

